<template>
<v-container>
  <div>
      <h2>test</h2>
    <button @click="printDownload">Print Download</button>
    <Download v-show="false" ref="DownloadComp" />
  </div>
  </v-container>
</template>

<script>
  import Download from 'download';
  export default {
    components: {
      Download,
    },
    methods: {
      printDownload () {
          let w = window.open()
          w.document.write(this.$refs.DownloadComp.$el.innerHTML)
          w.document.close()
          w.setTimeout(function () {
            w.print()
          }, 1000)
      }
    },
  }
</script>